<template>
  <v-card @keypress.enter="loginEnter" rounded class="px-5 py-2">
    <v-alert type="error" v-if="errorAlertMessage">{{errorAlertMessage}}</v-alert>
    <v-form v-model="formValid">
    <v-container>
      <div class="display-1 text-center pb-4" id="loginHeading">Přihlášte se</div>
      <v-row>
        <v-col cols="12">
          <span class="form-heading">E-mail</span>
            <v-text-field
              type="email"
              :rules="[validation.required]"
              v-model.trim="form.email"
              outlined
              data-email-input
              id="emailInput"
              required
            >
            </v-text-field>
          <span class="form-heading">Heslo</span>
            <v-text-field
              type="password"
              id="passwordInput"
              required
              :rules="[validation.required]"
              v-model.trim="form.password"
              outlined
              data-password-input
            >
            </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="hotelsBlue"
            block
            rounded
            class="text-center py-6 text-lowercase"
            :disabled="!formValid || runningRequest"
            id="loginButton"
            @click="login()"
            data-login-button
            :loading="runningRequest"
          >
            Přihlásit se
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  name: 'Login',
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      formValid: true,
      runningRequest: false,
      errorAlertMessage: null
    }
  },
  methods: {
    login () {
      this.runningRequest = true
      this.resetAuth()
      this.clearAlerts()
      this.$http.post('/login', this.form)
        .then(response => {
          this.setUser(response.data.user)
          this.setToken(response.data.token)
          this.$emit('login-success')

          if (this.isAdmin) {
            this.$router.push({ name: 'admin.dashboard' })
          }
          if (this.isHost) {
            this.$router.push({ name: 'host.dashboard' })
          }
        })
        .catch(error => {
          let errorMessage = ''
          // console.log(error.response)
          if (error.response.data.message === 'NOT_VERIFIED') {
            errorMessage = 'Neověřený e-mail'
          } else if (error.response.status === 401) {
            errorMessage = 'Neplatné přihlašovací údaje'
          }

          this.errorAlertMessage = errorMessage
          this.clearAlerts()
          // this.setAlerts([{ message: errorMessage, type: 'error' }])
        }).finally(() => {
          this.runningRequest = false
        })
    },
    loginEnter () {
      if (this.formValid) {
        this.login()
      }
    },
    ...mapMutations({
      resetAuth: 'userAuth/resetAuth',
      setToken: 'userAuth/setToken',
      clearAlerts: 'alerts/clearAlerts',
      setUser: 'userAuth/setUser'
    }),
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  computed: {
    ...mapGetters({
      isAdmin: 'userAuth/isAdmin',
      isHost: 'userAuth/isHost'
    })
  }
}
</script>

<style scoped>
#loginHeading{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 29px;
}
.form-heading{
  font-family: 'Readex Pro', sans-serif;
  font-weight: bold;
  font-size: 16px;
}
#loginButton{
  color: white;
  border-radius: 15px;
  font-family: 'Readex Pro', sans-serif;
  font-size: 15px;
  font-weight: bold;
}
</style>
