<template>
  <div>
    <v-app-bar
      elevation="0"
      app
    >
      <div class="d-flex align-center">
        Rezervační systém
      </div>
      <v-divider class="ml-6" vertical inset></v-divider>
      <v-spacer></v-spacer>

      <div
        v-if="!user.id"
        class="d-flex align-center topbar">
        <v-select
          class="align-center"
          rounded
          hide-details
          :items="lang"
          value="CZ"
          dense
          style="width: 100px"
          filled>
        </v-select>
        <v-btn
          icon>
          <v-icon color="amber darken-4">mdi-help-circle-outline</v-icon>
        </v-btn>
        <v-btn
          text
          elevation="0"
          @click="loginFormVisible = true"
          class="text-lowercase font-weight-bold"
          color="amber darken-4"
        >Přihlásit se</v-btn>
        <v-btn
          dark
          class="text-lowercase rounded-4 font-weight-bold"
          elevation="0"
          @click="registerFormVisible = true"
          color="amber darken-4"
        >Registrovat se</v-btn>
      </div>
      <div v-else class="d-flex align-center">
        <v-select
          class="align-center"
          rounded
          hide-details
          :items="lang"
          value="CZ"
          dense
          style="width: 100px"
          filled>
        </v-select>
        <v-btn
          v-if="user.id"
          icon>
          <v-icon color="amber darken-4">mdi-help-circle-outline</v-icon>
        </v-btn>
        <v-btn
          link
          v-if="user.id && !isHost"
          :to="{name: `user.chats`}"
          icon>
          <v-icon color="amber darken-4">mdi-forum-outline</v-icon>
        </v-btn>
        <v-btn
          link
          v-if="user.id && isHost"
          :to="{name: `host.chats`}"
          icon>
          <v-icon color="amber darken-4">mdi-forum-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="user.id"
          icon>
          <v-icon color="amber darken-4">mdi-bell-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="user.id && !isAdmin"
          link
          :to="{name: `user.favourite-properties`}"
          icon>
          <v-icon color="amber darken-4">mdi-heart-outline</v-icon>
        </v-btn>
        <v-list dense color="transparent">
          <v-list-item two-line>
            <v-list-item-avatar>
              <img src="https://randomuser.me/api/portraits/women/81.jpg">
            </v-list-item-avatar>

            <v-list-item-content>
              <router-link :to="{ name: 'user.profile' }" style="color: #FF6F00" class="mr-3">
                <v-list-item-title>{{ user.nickname }}</v-list-item-title>
              </router-link>
              <v-list-item-subtitle
                v-if="user.credit"
              >{{ user.credit }} Kreditů
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn
          @click="logout"
          text
          elevation="0"
          class="text-lowercase font-weight-bold"
          color="amber darken-4"
        >
          Odhlásit se
        </v-btn>
        <v-btn
          v-if="!isHost && !isAdmin"
          light
          class="rounded-0"
          elevation="0"
          @click="registerHostVisible = true"
        >Chci být hostitel</v-btn>
        <v-btn
          v-if="isHost"
          dark
          color="amber darken-4"
          class="text-lowercase rounded-4 font-weight-bold"
          elevation="0"
          :to="{name: `host.dashboard`}"
        >Administrace</v-btn>
      </div>
    </v-app-bar>
    <v-toolbar color="light-blue accent-3" id="toolbar" >
      <v-container style="height: inherit; padding-top: 0">
        <v-toolbar-items nav class="text-body-2 font-weight-bold navbar">
          <v-btn class="rounded-0 font-weight-bold" text color="white" link :to="{name: `homepage`}">Domů</v-btn>
          <v-btn class="rounded-0 font-weight-bold" text color="white" v-if="user.id && !isHost && !isAdmin" link :to="{name: `user.demands`}">Poptávky</v-btn>
          <v-btn class="rounded-0 font-weight-bold" text color="white" v-if="user.id && !isHost && !isAdmin" link :to="{name: `user.reservations`}">Rezervace</v-btn>
          <v-btn class="rounded-0 font-weight-bold" text color="white" v-if="user.id && !isHost && !isAdmin" link :to="{name: `user.chats`}">Zprávy</v-btn>
          <v-btn class="rounded-0 font-weight-bold" text color="white" link :to="{name: `about`}">O našem webu</v-btn>
          <v-btn class="rounded-0 font-weight-bold" text color="white" link :to="{name: `contacts`}">Kontakty</v-btn>
        </v-toolbar-items>
      </v-container>
    </v-toolbar>
    <v-dialog
      transition="dialog-top-transition"
      class="rounded-0"
      v-model="loginFormVisible"
      width="400"
    >
      <Login
        @login-success="loginFormVisible = false"
        v-if="loginFormVisible"
      ></Login>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      class="rounded-0"
      v-model="registerFormVisible"
      width="600">
      <Register
        @registration-success="registerFormVisible = false"
        v-if="registerFormVisible"
      ></Register>
    </v-dialog>
    <v-dialog v-model="registerHostVisible">
      <RegisterHost
        v-if="registerHostVisible"
        @registration-success="showRegistrationHostSuccess"
      />
    </v-dialog>
  </div>
</template>

<script>
import Login from '@/components/auth/Login'
import Register from '@/components/auth/Register'
import { mapActions, mapGetters } from 'vuex'
import RegisterHost from '../../auth/RegisterHost'
export default {
  name: 'Navbar',
  components: { RegisterHost, Register, Login },
  data () {
    return {
      lang: ['CZ'/* , 'EN' */],
      loginFormVisible: false,
      registerFormVisible: false,
      registerHostVisible: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'userAuth/user',
      isHost: 'userAuth/isHost',
      isAdmin: 'userAuth/isAdmin'
    })
  },
  methods: {
    showRegistrationHostSuccess () {
      this.setAlerts([{ message: 'Registrace hostitele proběhla úspěšně.', type: 'success' }])
      this.registerHostVisible = false
    },
    ...mapActions({
      logout: 'userAuth/logout',
      setAlerts: 'alerts/setAlerts',
      loadAlerts: 'alerts/loadAlerts'
    })
  },
  watch: {
    user: {
      deep: true,
      handler (val) {
        // zobrazovani/schovavani alertu pri prihlaseni/odhlaseni - jen pro usery a hostitele
        if (val.id && (val.roleId === 3 || val.roleId === 4)) {
          this.loadAlerts()
        }
        if (!val.id) {
          this.$store.commit('alerts/removePermanentAlerts')
        }
      }
    }
  },
  created () {
    if (this.user.id && (this.user.roleId === 3 || this.user.roleId === 4)) {
      this.loadAlerts()
    }
  }
}
</script>

<style scoped type="text/css">
.navbar{
  font-family: 'Noto Sans', sans-serif !important;
}
.topbar{
  font-family: 'Readex Pro', sans-serif;
}
.v-list-item{
 padding: 0;
}
</style>
