<template>
  <v-card>
      <v-container class="px-5">
        <div class="display-1 text-center">Registrace hostitele</div>
        <v-form v-model="formValid" v-if="!fetchingUserInfo">
        <v-row class="mt-4">
          <v-col cols="12">
            <v-text-field
              v-model.trim="form.companyName"
              type="text"
              label="Název firmy"
              :rules="[validation.required, validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.ico"
              type="number"
              label="IČO"
              :rules="[validation.required, validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.dic"
              :rules="[validation.maxLength(255)]"
              type="text"
              label="DIČ"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.firstName"
              label="Jméno"
              :rules="[validation.required, validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.lastName"
              type="text"
              :rules="[validation.required, validation.maxLength(255)]"
              label="Příjmení"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model.trim="form.street"
              type="text"
              label="Adresa"
              :rules="[validation.required, validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.trim="form.streetNumber"
              type="text"
              :rules="[validation.required, validation.maxLength(255)]"
              label="Číslo popisné"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model.trim="form.city"
              type="text"
              label="Město"
              :rules="[validation.required, validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model.trim="form.zip"
              :rules="[validation.required, validation.maxLength(255)]"
              type="text"
              label="PSČ"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.phone"
              :rules="[validation.required, validation.maxLength(255)]"
              type="tel"
              label="Telefon"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.trim="form.phone2"
              :rules="[validation.maxLength(255)]"
              type="tel"
              label="Druhý telefon"
              outlined
              dense
              class="rounded-0"
              hide-details>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn
              :disabled="!formValid || runningRequest"
              @click="register"
              color="primary" class="rounded-0">Registrovat se jako hostitel</v-btn>
          </v-col>
        </v-row>
        </v-form>
        <v-skeleton-loader v-else type="list-item@3" />
      </v-container>
  </v-card>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'RegisterHost',
  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        companyName: null,
        ico: null,
        dic: null,
        street: null,
        streetNumber: null,
        city: null,
        zip: null,
        phone: null,
        phone2: null
      },
      formValid: true,
      runningRequest: false,
      fetchingUserInfo: false
    }
  },
  methods: {
    register () {
      this.runningRequest = true
      this.$http.post('/register-host', {
        ...this.form,
        email: this.user.email
      })
        .then(res => {
          this.setUser(res.data)
          this.$emit('registration-success')
        })
    },
    ...mapMutations({
      setUser: 'userAuth/setUser'
    })
  },
  computed: {
    ...mapGetters({
      user: 'userAuth/user'
    })
  },
  async created () {
    this.fetchingUserInfo = true
    const data = (await this.$http.get(`/users/${this.user.id}`)).data
    this.form = { ...data, dic: null }
    delete this.form.id
    delete this.form.nickname

    this.fetchingUserInfo = false
  }
}
</script>

<style scoped>

</style>
