<template>
  <v-card>
    <v-form v-model="formValid" ref="form">
      <v-alert  v-if="alertMessage" :type="alertMessageType">{{alertMessage}}</v-alert>
      <v-container class="px-5">
        <div class="display-1 text-center">Registrace</div>
        <v-row class="mt-4">
          <v-col cols="6">
            <v-text-field
              v-model="form.email"
              type="email"
              label="Email*"
              :rules="[validation.required, validation.email, validation.maxLength(255)]"
              outlined
              dense
              class="rounded-0"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.nickname"
              :rules="[validation.required, validation.maxLength(255)]"
              type="text"
              label="Přezdívka*"
              outlined
              dense
              class="rounded-0"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.password"
              :rules="[validation.required, validation.minLength(6)]"
              type="password"
              label="Heslo*"
              outlined
              dense
              class="rounded-0"
              required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="form.passwordConfirmation"
                type="password"
                label="Heslo znovu*"
                :rules="[validation.required, validation.equal(form.password)]"
                outlined
                dense
                class="rounded-0"
                required>
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :rules="[validation.maxLength(255)]"
              v-model="form.firstName"
              type="text"
              label="Jméno"
              outlined
              dense
              class="rounded-0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.lastName"
              :rules="[validation.maxLength(255)]"
              type="text"
              label="Příjmení"
              outlined
              dense
              class="rounded-0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="tos"
              :rules="[validation.required]"
              label="Souhlasím s VOP a GDPR"
              dense
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-center">
            <v-btn
              color="primary"
              class="rounded-0 mb-3"
              :disabled="!formValid || runningRequest" @click="register"
              :loading="runningRequest"
            >Registrovat se</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'Register',
  data () {
    return {
      form: {
        email: '',
        nickname: '',
        password: '',
        passwordConfirmation: '',
        firstName: '',
        lastName: ''
      },
      tos: false,
      runningRequest: false,
      formValid: true,
      alertMessage: '',
      alertMessageType: 'success'
    }
  },
  methods: {
    register () {
      this.alertMessage = ''
      this.resetAuth()
      this.runningRequest = true
      this.$http.post('/register', this.form)
        .then(res => {
          this.setAlerts([{ message: 'Registrace proběhla úspěšně', type: 'success' }])
          this.$emit('registration-success')
        })
        .catch(err => {
          this.clearAlerts()
          const errors = err.response.data

          if (err.response.data.message === 'EMAIL_ALREADY_USED') {
            this.alertMessage = 'E-mail je již používán'
            this.alertMessageType = 'error'
          }

          if (errors.nickname && errors.nickname.indexOf('This nickname has been already used') !== -1) {
            this.alertMessage = 'Přezdívka je již používána'
            this.alertMessageType = 'error'
          }
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    ...mapMutations({
      resetAuth: 'userAuth/resetAuth',
      clearAlerts: 'alerts/clearAlerts'
    }),
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  }
}
</script>

<style scoped>
.col{
  padding-top: 0;
  padding-bottom: 0;
}
</style>
